<template>
    <div class="mt-8">
        <hooper :settings="hooperSettings" class="h-full">
            <slide v-for="(videos, i) in videos" :key="i" >
                <iframe width="95%" height="315px" class="mx-auto" :src="videos.link" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe>
            </slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
    </div>
</template>

<script>
import "hooper/dist/hooper.css";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";


export default {
    name: 'YourComponent',
    components: {
        Hooper,
        Slide,
        HooperNavigation,
    },
    data() {
        return {
            hooperSettings: {
                itemsToShow: 1,
                centerMode: true,
                autoPlay: false,
                infiniteScroll: true,
                hoverPause: false,
                wheelControl: false,
                playSpeed: 5000,
                breakpoints: {
                    767: {
                        centerMode: false,
                        itemsToShow: 2,
                    },
                    1000: {
                        itemsToShow: 3,
                        centerMode: false,
                    },
                },
            },
            videos: [
                {
                    link: 'https://www.youtube.com/embed/Tfz72fOA_Eg?si=RByCK832m8_iDbf2',
                },
                {
                    link: 'https://www.youtube.com/embed/au31ozmWGd0?si=QFGMMgrrChxCM1Qa"',
                },
                {
                    link: 'https://www.youtube.com/embed/ghPEwUZbDLU?si=wNQH86c7sI8O0foM',
                },
                {
                    link: 'https://youtube.com/embed/_RYNQFVCe8k?si=Tn0jvSQg5UvQ2uOt',
                },
                {
                    link: 'https://www.youtube.com/embed/oCu4G5NBHDI?si=0CWq9v6qCuVmk_54',
                },
                {
                    link: 'https://youtube.com/embed/CvB3bNqourk?si=JJeDpOlPCXNnSAog',
                },
            ],
        };
    },
};
</script>