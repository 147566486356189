<template>
  <div>
    <div class="mt-6">
      <carousel
        :autoplay="true"
        :loop="true"
        :autoplaySpeed="2500"
        :autoplayTimeout="6000"
        :responsive="{
          0: { items: 1, nav: false, dots: true },
          767: { items: 2, nav: false, dots: true },
          1000: { items: 3, nav: false, dots: true },
        }"
      >
        <div v-for="(item, i) in testimonials" :key="i" class="px-1">
          <div class="p-5 rounded-md bg-green-50">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="w-10 h-10 fill-current text-yellow-1"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"
              />
            </svg>
            <div class="pt-3">
              <p class="text-sm text-gray-600 h-52">
                {{ item.p }}
              </p>
            </div>
            <div class="pt-4">
              <p class="text-lg font-semibold">{{ item.client }}</p>
              <!-- <div class="text-yellow-400">
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
              </div> -->
            </div>
          <div class="absolute w-20 bottom-3 right-5">
            <img src="/images/google-review.png" alt="" class="" />
          </div>
          </div>
        </div>
      </carousel>
    </div>
    <div class="hidden">
      <img src="/images/bg-testimonial.jpg" alt="" />
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      testimonials: [
        {
          p: "Strongly recommend for choosing Avenue Design. They give me many pratical idea for my kitchen and provide good design for my house！Also thanks for their professional worker team and good workmanship.",
          client: "Bee Siah",
        },
        {
          p:'I’m very satisfied with the finishing kitchen setup. It’s exactly same as my expectation. The designer (Alvin) is friendly and professional and provides us many good recommendations along the process',
          client:'Camie Tan',
        },
        {
          p:'Very satisfied their service. Thank you for their professional to let me get a nice cabinet design for my house. Highly recommend！',
          client:'LIM PEI SHAN',
        },
        {
          p:'Very satisfied with the design and outcome!! Good quality and workmanship Special thanks to Elaine who in charge and guide/ advice us from design stage until the completion. Very professional, responsible, and helpful.',
          client:'Jitsen Chong',
        },
        {
          p:'Well, if you don’t know from where to start then Avenue Design Holding is the best people to give you the solution. They are kind and friendly Elaine,Ivan and Daniel wonderful people who helped me to complete my home renovation work',
          client:'Nimala Manoj ',
        },
        {
          p:"I've had positive experience with Avenue Design for my wardrobe installation and TV panel relocation. Starting from quick response through whatsapp inquiry, friendly and helpful customer service at the store by Ms Fiezah and her colleague",
          client:'Mohammad Najib',
        },
        
      ],
    };
  },
};
</script>
