<template>
  <div>
    <!-- header/banner -->
    <!-- <HomeModal /> -->
    <WaBtn />
    <MainBanner />

    <!-- product section  -->
    <div id="product" class="py-10">
      <div class="container">
        <h2 class="text-3xl font-light text-center text-brown-2">What We Do</h2>

        <div
          class="mt-6 space-y-2 text-sm leading-tight text-center text-gray-600 lg:mx-auto lg:max-w-3xl md:leading-tight md:text-base">
          <p>
            <b>AVENUE DESIGN HOLDING SDN BHD</b> is a company that specializes
            in customized kitchen cabinets and built-in wardrobes. Our main
            office is located in Petaling Jaya, Selangor, Malaysia. We provides
            a series of custom made cabinets for living room, bedrooms to
            kitchen.
          </p>

          <p>
            Our friendly experts will work with customers through listening and
            understanding their needs in order to provide the best consultation
            services.
          </p>
        </div>

        <div class="mt-5 md:flex md:flex-wrap">
          <div v-for="(item, i) in product" :key="i" class="pb-3 md:p-2 md:pb-0 lg:px-1 md:w-1/2 lg:w-1/3">
            <div class="relative">
              <img :src="'/images/' + item.image + '.jpg'" :alt="item.alt" class="" />
              <div
                class="absolute top-0 left-0 z-0 w-full h-full transition duration-300 ease-in-out bg-opacity-40 bg-brown-2 hover:bg-opacity-90">
                <p class="absolute text-lg font-medium text-white top-1/2 left-6">
                  {{ item.alt }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- location -->
    <div id="showroom" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-location.jpg')">
      <div class="container py-20">
        <div class="px-4 py-10 md:w-2/3 lg:w-5/6 md:mx-auto bg-brown-1">
          <h2 class="text-3xl font-light text-center text-white capitalize">
            Visit Our <br class="lg:hidden" />
            Showrooms Now!
          </h2>
          <div class="lg:flex lg:mt-6">
            <div class="flex py-6 text-white border-b border-gray-200 lg:px-4 lg:border-b-0 lg:border-r lg:w-1/3">
              <div class="w-2/12">
                <img src="/images/loc-1.png" alt="" class="" />
              </div>
              <div class="w-10/12 pl-3 text-sm">
                <p class="font-semibold">PJ SHOWROOM</p>
                <div class="inline-flex">
                  <i class="pr-2 text-xl fa-brands fa-waze"></i>
                  <a href="https://www.waze.com/en/live-map/directions/my/selangor/pj/ldp-furniture-mall?place=ChIJZU6l_3RMzDERkA3k84vTE_M"
                    class="block pt-1 text-xs">
                    LDP Furniture Mall, Lot 9, Unit 3A, Jalan SS 8/1, 47301
                    Petaling Jaya, Selangor, Malaysia
                  </a>
                </div>
                <div class="pt-2">
                  <p class="font-semibold">Monday - Sunday</p>
                  <p class="pt-1 text-xs">11:00 AM - 8:00 PM</p>
                </div>
              </div>
            </div>
            <div class="flex py-5 text-white border-b border-gray-200 lg:px-4 lg:border-b-0 lg:border-r lg:w-1/3">
              <div class="w-2/12">
                <img src="/images/loc-1.png" alt="" class="" />
              </div>
              <div class="w-10/12 pl-3 text-sm">
                <p class="font-semibold">SETIA ALAM SHOWROOM</p>
                <div class="inline-flex">
                  <i class="pr-2 text-xl fa-brands fa-waze"></i>
                  <a href="https://www.waze.com/en/live-map/directions/my/selangor/shah-alam/avenue-design-holding-sdn-bhd-(setia-alam)?navigate=yes&place=ChIJg5yVtiNTzDER4H-McKGK8ws"
                    class="block pt-1 text-xs">
                    No.1-3-G, Jalan Setia Prima W U13/W, Setia Alam, Seksyen
                    U13, 40170 Shah Alam, Selangor, Malaysia
                  </a>
                </div>
                <div class="pt-2">
                  <p class="font-semibold">Monday - Sunday</p>
                  <p class="pt-1 text-xs">11:00 AM - 8:00 PM</p>
                </div>
              </div>
            </div>
            <div class="flex py-5 text-white lg:px-4 lg:w-1/3">
              <div class="w-2/12">
                <img src="/images/loc-4.png" alt="" class="" />
              </div>
              <div class="w-10/12 pl-3 text-sm">
                <p class="font-semibold">FACTORY</p>
                <div class="inline-flex">
                  <i class="pr-2 text-xl fa-brands fa-waze"></i>
                  <a href="https://www.waze.com/en/live-map/directions/my/selangor/puchong/avenue-design-holding-sdn-bhd-puchong-showroom-and-factory?navigate=yes&place=ChIJlZ-bmLuxzTERxQHqoRt5qJU"
                    class="block pt-1 text-xs">
                    AVENUE ID INDUSTRIES SDN BHD <br />
                    No.32A, Jalan PP 11/4, Alam Perdana, Taman Putra Perdana,
                    Puchong, 47130, Selangor, Malaysia
                  </a>
                </div>
                <div class="pt-2">
                  <p class="font-semibold">Monday - Saturday</p>
                  <p class="pt-1 text-xs">8:30AM -6:00PM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- packages  -->
    <div id="packages" class="py-10">
      <div class="container">
        <p class="text-xl text-center">High Quality Kitchen Cabinets</p>
        <h2 class="pt-2 text-3xl font-bold text-center text-brown-2">
          Our Latest Promotion, <br class="md:hidden" />
          Save BIG Now
        </h2>

        <div class="mt-6 xl:mt-20 md:flex md:flex-wrap xl:items-center">
          <div v-for="(item, i) in packages" :key="i" class="mb-6 md:px-2 md:w-1/2 xl:px-3" :class="item.Width">
            <img :src="'/images/' + item.image + '.jpg'" :alt="item.alt" class="" />
            <div class="pt-2 text-center">
              <p class="text-3xl font-semibold uppercase lg:text-2xl text-brown-1">
                {{ item.alt }}
              </p>
              <p class="text-2xl text-gray-600 lg:text-base">{{ item.type }}</p>
              <div class="inline-flex pt-2 font-semibold">
                <p>RM</p>
                <p class="text-4xl">{{ item.price }}</p>
              </div>
            </div>
            <div class="flex justify-center pt-4 space-x-1 text-sm text-center">
              <p id="cta-view"
                class="w-32 py-1 mx-0 text-gray-600 transition duration-300 ease-in-out border border-brown-2 hover:bg-brown-2 hover:text-white">
                <a :href="item.pdf">View More</a>
              </p>
              <p class="w-32 py-1 mx-0 text-white bg-brown-1">
                <a :href="'https://wa.me/60163178112?text=' + item.text">Get This Promo</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10 bg-brown-1">
      <div class="container">
        <h2 class="text-3xl font-light text-center text-white">
          Why Choose Us
        </h2>
        <p class="mt-5 text-sm text-center text-gray-100 lg:mx-auto lg:w-2/3">
          We offer a 100% satisfaction guarantee with no gimmick and provides
          complete transparent pricing.
        </p>

        <div class="mt-6 md:flex md:flex-wrap">
          <div v-for="(item, i) in choose_us" :key="i" class="mb-4 md:px-1 md:w-1/2 lg:w-1/4">
            <div class="relative">
              <img :src="'/images/' + item.bgImg + '.jpg'" alt="" class="lg:w-full" />
              <div class="absolute top-0 w-full h-full bg-black bg-opacity-60">
                <div
                  class="absolute w-full px-4 text-center text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  <img :src="'/images/' + item.icon + '.png'" :alt="item.alt" class="w-16 mx-auto lg:w-12" />
                  <p class="pt-3 text-lg">{{ item.alt }}</p>
                  <p class="pt-4 text-sm leading-tight text-gray-100">
                    {{ item.p }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p class="mt-10 text-sm text-center text-gray-100 lg:w-2/3 lg:mx-auto">
            Not sure what design suits your home? Fret not, our professional
            team is ever ready to assist you, all the way from the process of
            designing to final installation.
          </p>
        </div>

        <div class="text-center text-white">
          <div class="pt-5">
            <!-- <p class="font-bold">Message Us on WhatsApp</p> -->
            <p class="font-medium">
              <i class="pr-2 text-lg fa-brands fa-whatsapp"></i>
              <a href="https://wa.me/60123948112?text=Hi,%20I%20would%20like%20to%20enquire%20on%20custom%20cabinet" class="hover:text-yellow-1">012-394 8112</a>
              |
              <a href="https://wa.me/60163178112?text=Hi,%20I%20would%20like%20to%20enquire%20on%20custom%20cabinet" class="hover:text-yellow-1">016-317 8112</a>
            </p>
          </div>

          <div class="pt-2 font-medium">
            <!-- <p class="font-bold">Call Us</p> -->
            <p class="font-medium">
              <i class="pr-2 text-lg fa-solid fa-phone"></i>
              <a href="tel:+60123948112" class="hover:text-yellow-1">012-394 8112</a>
              |
              <a href="tel:+60163178112 " class="hover:text-yellow-1">016-317 8112</a>
            </p>
          </div>
        </div>
        <!-- <div
          class="mt-6 space-y-1 md:flex md:justify-center md:space-x-1 md:space-y-0"
        >
          <p
            class="py-1 mx-auto text-center text-white md:mx-0 w-44 bg-green-1"
          >
            <a href="https://wa.me/60163178112">Talk To Us</a>
          </p>
          <p
            class="py-1 mx-auto text-center text-white md:mx-0 w-44 bg-green-1"
          >
            <a href="tel:+60123948112">Get A Free Quote</a>
          </p>
        </div> -->
      </div>
    </div>

    <!-- sub-banner -->
    <div class="py-10 bg-no-repeat bg-cover bg-adjust" style="background-image: url('/images/bg-sub-1.jpg')">
      <div class="container">
        <div class="py-10">
          <div class="w-3/5 ml-auto text-right md:w-1/3">
            <p class="pb-3 text-3xl font-normal text-brown-2">Our Brand</p>
            <p class="pb-3 text-lg leading-tight text-brown-1 md:text-xl xl:text-2xl">
              Sorento is one of the leading brands for kitchen and bathroom
              sanitary wares in Malaysia.
            </p>
            <img src="/images/logo-sorento.png" alt="" class="ml-auto w-52" />
          </div>
        </div>
      </div>
    </div>

    <!-- certificate -->
    <div class="py-10">
      <div class="container">
        <h2 class="text-3xl font-light text-center text-brown-2">Our Awards</h2>

        <div class="mt-8 lg:max-w-3xl lg:mx-auto">
          <div class="spacey-y-3 lg:flex lg:items-center">
            <div class="md:w-2/3 lg:w-1/2 md:mx-auto">
              <img src="/images/certi-1.jpg" alt="" class="mx-auto">
            </div>
            <div class="md:w-2/3 lg:w-1/2 md:mx-auto">
              <img src="/images/certi-2.jpg" alt="" class="mx-auto">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Gallery -->
    <div id="gallery" class="py-10">
      <div class="container">
        <h2 class="text-3xl font-light text-center text-brown-2">Our Works</h2>
        <div class="mt-8">
          <hooper :settings="hooperSettings" class="h-full">
            <slide v-for="(slide, indx) in gallery" :key="indx" :index="indx" class="px-2">
              <img :src="'/images/' + slide + '.jpg'" alt="" />
            </slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
          </hooper>
        </div>
      </div>
    </div>

    <!-- Videos -->
    <div id="video" class="py-10">
      <div class="container">
        <h2 class="text-3xl font-light text-center text-brown-2">Our Videos</h2>
        <div class="mt-8">
          <OurVideo />
        </div>
      </div>
    </div>



    <!-- testimonials -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h2 class="text-3xl font-light text-center text-brown-2">
          What Our Customers Say About Us
        </h2>
        <Testimonials class="pt-6" />
      </div>
    </div>

    <!-- footer -->
    <div id="element" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')">
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 rounded-md bg-brown-2 bg-opacity-80">
            <div class="text-center">
              <p class="text-3xl font-semibold text-white">Send an Enquiry</p>
              <div class="bg-white w-14 h-0.5 mx-auto my-3"></div>
              <p class="pt-3 text-sm text-gray-100">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-5 lg:w-1/2 lg:pr-4">
          <img src="/images/main-logo-white.png" alt="" class="mx-auto w-60 md:w-80" />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h1 class="pb-2 text-lg font-bold lg:text-xl text-blue-1">
                Avenue Design Holding Sdn Bhd
              </h1>
              <div class="pt-2">
                <p class="font-bold">PJ SHOWROOM</p>
                <p class="pt-2 text-sm">
                  LDP Furniture Mall, Lot 9, Unit 3A, Jalan SS 8/1, 47301
                  Petaling Jaya, Selangor, Malaysia
                </p>
              </div>
              <div class="pt-2">
                <p class="font-bold">SETIA ALAM SHOWROOM</p>
                <p class="pt-2 text-sm">
                  No.1-3-G, Jalan Setia Prima W U13/W, Setia Alam, Seksyen U13,
                  40170 Shah Alam, Selangor, Malaysia
                </p>
              </div>
              <div class="pt-4">
                <p class="font-bold">
                  FACTORY
                  <br />
                  AVENUE ID INDUSTRIES SDN BHD
                </p>
                <p class="pt-2 text-sm">
                  No.32A, Jalan PP 11/4, Alam Perdana, Taman Putra Perdana,
                  Puchong, 47130, Selangor, Malaysia
                </p>
              </div>
              <div class="pt-5">
                <!-- <p class="font-bold">Message Us on WhatsApp</p> -->
                <p class="font-medium">
                  <i class="pr-2 text-lg fa-brands fa-whatsapp"></i>
                  <a href="https://wa.me/60123948112?text=Hi,%20I%20would%20like%20to%20enquire%20on%20custom%20cabinet" class="hover:text-yellow-1">012-394 8112</a>
                  |
                  <a href="https://wa.me/60163178112?text=Hi,%20I%20would%20like%20to%20enquire%20on%20custom%20cabinet" class="hover:text-yellow-1">016-317 8112</a>
                </p>
              </div>
              <div class="pt-2 font-medium">
                <!-- <p class="font-bold">Call Us</p> -->
                <p class="font-medium">
                  <i class="pr-2 text-lg fa-solid fa-phone"></i>
                  <a href="tel:+60123948112" class="hover:text-yellow-1">012-394 8112</a>
                  |
                  <a href="tel:+60163178112 " class="hover:text-yellow-1">016-317 8112</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-3 bg-brown-2">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Testimonials from "@/components/Testimonials.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";
import OurVideo from "@/components/OurVideo.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
import WaBtn from "@/components/WaBtn.vue";
// import HomeModal from "@/components/HomeModal.vue"

export default {
  name: "Home",
  components: {
    Testimonials,
    EnquiryForm,
    MainBanner,
    Hooper,
    OurVideo,
    Slide,
    HooperNavigation,
    WaBtn,
    // HomeModal
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        autoPlay: true,
        infiniteScroll: true,
        hoverPause: false,
        wheelControl: false,
        playSpeed: 5000,
        breakpoints: {
          767: {
            centerMode: false,
            itemsToShow: 2,
          },
          1000: {
            itemsToShow: 3,
            centerMode: false,
          },
        },
      },
      product: [
        { image: "product-1", alt: "Kitchen Cabinet" },
        { image: "product-2", alt: "Built in Wardrobe" },
        { image: "product-3", alt: "TV Cabinet" },
        { image: "product-4", alt: "Display Cabinet" },
        { image: "product-5", alt: "Kid’s Bedroom Cabinet" },
        { image: "product-6", alt: "Study Table" },
        { image: "product-7", alt: "Kitchen Island" },
        { image: "product-8", alt: "Shoe Cabinet" },
        { image: "product-9", alt: "Staircase Cabinet" },
      ],
      packages: [
        {
          image: "package-1",
          alt: "Package A",
          type: "Melamine",
          price: "4950",
          pdf: "/images/pdf/package-1.pdf",
          Width: "xl:w-1/4",
          text: "I%20would%20like%20to%20get%20more%20details%20for%20Package%20A",
        },
        {
          image: "package-2",
          alt: "Package B",
          type: "Melamine",
          price: "6950",
          pdf: "/images/pdf/package-2.pdf",
          Width: "xl:w-1/4",
          text: "I%20would%20like%20to%20get%20more%20details%20for%20Package%20B",
        },
        {
          image: "package-3",
          alt: "Package C",
          type: "Hi Gloss 4G/Acrylic",
          price: "7200",
          pdf: "/images/pdf/package-3.pdf",
          Width: "xl:w-1/4 xl:transform  xl:scale-120",
          text: "I%20would%20like%20to%20get%20more%20details%20for%20Package%20C",
        },
        {
          image: "package-4",
          alt: "Package D",
          type: "Hi Gloss 4G/Acrylic",
          price: "9300",
          pdf: "/images/pdf/package-4.pdf",
          Width: "xl:w-1/4",
          text: "I%20would%20like%20to%20get%20more%20details%20for%20Package%20D",
        },
      ],

      choose_us: [
        {
          bgImg: "choose-4",
          icon: "icon-4",
          alt: "Direct from Factory",
          p: "To provide the best quality and cheapest in the custom-made cabinet markets.",
        },
        {
          bgImg: "choose-1",
          icon: "icon-1",
          alt: "Customization",
          p: " Every design is tailored to meet your expectation. It’s your home, it’s your style.",
        },
        {
          bgImg: "choose-2",
          icon: "icon-2",
          alt: "Expertise",
          p: "Passionate and experience in kitchen and wardrobe design. We treat every home like our own.",
        },
        {
          bgImg: "choose-3",
          icon: "icon-3",
          alt: "Affordable",
          p: "Affordable package without compromising on quality. ",
        },
      ],
      gallery: [
        "gallery-1",
        "gallery-2",
        "gallery-3",
        "gallery-4",
        "gallery-5",
        "gallery-6",
        "gallery-7",
        "gallery-8",
        "gallery-9",
        "gallery-10",
      ],
    };
  },
  // mounted() {
  //   window.onload = (event) => {
  //     document.querySelector("#home-modal").classList.add("active");
  //   };
  // },
};
</script>

<style>
.bg-adjust {
  /* background-position: right; */
  background-position-x: 73%;
}

.text-shadow {
  text-shadow: 2px 2px 4px #2f2f2f;
}

.hooper-next,
.hooper-prev {
  background-color: rgba(255, 255, 255, 0.616);
  top: 50%;
}

@media (min-width: 1024px) {
  .customHeight {
    min-height: 800px;
  }
}

@media (min-width: 768px) {
  .bg-adjust {
    background-position: right !important;
  }
}
</style>
